<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  // import { PUBLIC_TEADS_ID } from "$env/static/public";
  import { settings_store, service_name } from "$lib/store.js";

  let allowAds = false;
  let adUnfilled = false;

  let teadsID = "";

  if (typeof window !== "undefined") {
    const hostname = window.location.hostname;
    if (hostname.endsWith("filmium.com")) {
      teadsID = "225334";
    } else if (hostname.endsWith("filmium.ch")) {
      teadsID = "225335";
    } else if (hostname.endsWith("filmium.at")) {
      teadsID = "225337";
    } else if (hostname.endsWith("filmium.de")) {
      teadsID = "225336";
    } else if (hostname.includes("localhost")) {
      teadsID = "190461";
    } else if (hostname.includes("dev.")) {
      teadsID = "190461";
    } else {
      teadsID = "225334";
    }
    console.log("teadsID: " + teadsID);
  }

  function initTeads(document) {
    if ($settings_store.consent_ad_cookies) {
      allowAds = true;

      let script = document.createElement("script");
      script.className = "teads";
      script.async = true;
      script.type = "text/javascript";
      script.src = "https://a.teads.tv/page/" + teadsID + "/tag";

      document.body.appendChild(script);
    }
  }

  function trackDonationBanner() {
    window._paq.push(["trackEvent", "donation-banner", "clicked"]);
  }

  onMount(() => {
    initTeads(window.document);

    setTimeout(() => {
      const adContainer = document.querySelector(".adsquare");
      if (adContainer) {
        const adIframe = adContainer.querySelector("iframe");
        if (!adIframe) {
          adUnfilled = true;
        }
      }
    }, 3000); // Wait 3 seconds to give the ad time to load
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="ad banner1">
  <div class="adsquare">
    {#if allowAds && !adUnfilled}
      <div class="teadsbanner" id="teadsbanner1"></div>
    {:else if !allowAds || adUnfilled}
      <div class="donationrequest">
        <h2>{$_("donation_head_1") + $service_name + $_("donation_head_2")}</h2>
        <p>{$_("donation_text_1") + $service_name + $_("donation_text_2") + $service_name + $_("donation_text_3")}</p>
        <a
          href="https://www.paypal.com/donate/?business=VCQTYXPW42KUL&no_recurring=0&item_name={$_(
            'paypal_button_text_1'
          ) +
            $service_name +
            $_('paypal_button_text_2')}&currency_code=CHF"
          rel="noopener noreferrer"
          on:click={() => trackDonationBanner()}
          target="_blank">
          <img class="donate-btn" alt="DONATE" />
        </a>
      </div>
    {/if}
  </div>
</div>

<!-- <div class="ad banner2"><div class="teadsbanner" id="teadsbanner2"></div></div> -->
<!-- <div class="ad banner3"><div class="teadsbanner" id="teadsbanner3"></div></div> -->

<!-- ---------------------------------------------------------------------- -->

<style>
  .ad {
    display: flex;
    grid-column: 1 / 3;
    height: 100%;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .adsquare {
    color: var(--mowizz-logo-blue);
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0.5rem;
    overflow: hidden;
    aspect-ratio: 1/1;
    object-fit: scale-down;

    height: auto;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    align-content: center;
  }
  .banner1 {
    grid-row: 3;
  }
  /* .banner2 {
    grid-column: -3 / -1;
    grid-row: 8;
  } */
  /* .banner3 {
    grid-row: 13;
  } */
  .donate-btn {
    content: url("/buttons/btn_donate.png");
    width: 240px;
    max-width: 100%;
    display: block;
    margin: auto;
  }
  .donate-btn:hover {
    content: url("/buttons/btn_donate_hover.png");
    cursor: pointer;
  }
  h2 {
    font-weight: 600;
  }
  .donationrequest {
    display: block;
    text-align: center;
    margin: auto;
    width: 96%;
    max-width: 300px;
  }
  @media (max-width: 863px) {
    .adsquare {
      width: 100%;
      position: relative;
    }
    .teadsbanner {
      width: 100%;
    }
    /* .banner2 {
      grid-row: 15;
    } */
    /* .banner3 {
      grid-row: 27;
    } */
  }
  @media (max-width: 626px) {
    .ad {
      grid-column: 1;
      /* place-items: center; */
    }
    .adsquare {
      width: 100%;
      position: relative;
    }
    .teadsbanner {
      width: 100%;
    }
    /* .banner2 {
      grid-row: 20;
    } */
    /* .banner3 {
      grid-row: 40;
    } */
  }
  @media (max-width: 430px) {
    .donationrequest p {
      display: none;
    }
  }
</style>
